<template>
  <div
    class="hero-slider"
    :key="`hero-slider-${$device.isDesktop ? 'desktop' : 'mobile'}`"
  >
    <HeroSliderImage
      v-if="carouselData.length > 0"
      @change-slide="(e) => changeSlide(e)"
      :slider-data="carouselData"
    />
    <HeroSliderBanner
      v-if="carouselData.length > 0 && bannerData.title"
      :title="bannerData.title"
      :link="bannerData.link"
      :cta="bannerData.cta"
    />
  </div>
</template>

<script>
import { defineComponent, useFetch, ref } from '@nuxtjs/composition-api';
import HeroSliderBanner from '~/components/HeroSlider/HeroSliderBanner.vue';
import HeroSliderImage from '~/components/HeroSlider/HeroSliderImage.vue';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { useI18n } from '@/helpers/hooks/usei18n';

export default defineComponent({
  name: 'HeroSlider',
  components: { HeroSliderImage, HeroSliderBanner },
  props: {},
  setup() {
    const { locale } = useI18n();
    const { blocks, loadBlocks } = useContent(`cmsBlockHeroSlider-${locale}`);
    const heroSliderSelector = `heroSlider-${locale}`;
    const blockData = ref(null);
    const currentSlideIndex = ref(0);
    const carouselData = ref([]);
    const bannerData = ref({});

    const updateBannerData = () => {
      bannerData.value = {
        title:
          carouselData.value?.[currentSlideIndex.value]?.text ||
          carouselData.value?.[0]?.text,
        link:
          carouselData.value?.[currentSlideIndex.value]?.link ||
          carouselData.value?.[0]?.link,
        cta:
          carouselData.value?.[currentSlideIndex.value]?.cta ||
          carouselData.value?.[0]?.cta,
      };
    };

    useFetch(async () => {
      await loadBlocks({ identifiers: [heroSliderSelector] });
      const blocksContent = contentGetters.getCmsBlockContent(blocks.value);
      try {
        carouselData.value =
          blocksContent[heroSliderSelector]?.data?.cards_array?.filter(
            (card) => !!card?.active && !!card?.images?.desktop
          ) || [];
        updateBannerData();
      } catch {
        console.warn('Could not load HeroSlider block data.');
      }
    });

    const changeSlide = (e) => {
      currentSlideIndex.value = e;
      updateBannerData();
    };

    return {
      changeSlide,
      currentSlideIndex,
      blockData,
      bannerData,
      carouselData,
    };
  },
});
</script>

<style lang="scss" scoped>
.hero-slider {
  margin-top: 84px;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: 100 100;
  @media (min-width: $desktop-min) {
    grid-template-columns: repeat(100, 1fr);
    grid-template-rows: minmax(450px, calc(100vh - 302px));
    .hero-slider-image {
      grid-row: 1 / 2;
    }
    .hero-slider-banner {
      grid-row: 1 / 4;
      min-height: 250px;
      height: fit-content;
    }
  }
  // when screen is larger than 1920px box the layout
  @media (min-width: 1921px) {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 1320px) and (orientation: landscape) {
    margin-top: 63px;
  }
  @media (max-width: 1023px) {
    grid-template-columns: 100vw;
    width: 100%;
    margin-top: 0;
  }
}
</style>

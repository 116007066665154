<template>
  <div id="home">
    <h1 class="seo-title">Homepage</h1>
    <HeroSlider />
    <ProductSlider
      :query-settings="featuredProductsQuerySettings"
      :slider-settings="featuredProductsSliderSettings"
      query="FeaturedProducts"
      :title="{ black: $t('Products'), red: $t('Featured') }"
    />
    <OffersBanner />
    <FeaturedCategories />
    <CategorySlider :is-desktop="isDesktop" />
    <NewProducts />
    <Organization />
  </div>
</template>

<script type="module">
import { computed, defineComponent, useFetch } from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@gemini/vsf-cache';
import HeroSlider from '~/components/HeroSlider.vue';
import ProductSlider from '~/components/ProductSlider.vue';
import OffersBanner from '@/components/OffersBanner/OffersBanner.vue';
import FeaturedCategories from '@/components/FeaturedCategories.vue';
import NewProducts from '@/components/NewProducts.vue';
import CategorySlider from '@/components/Category/CategorySlider.vue';
import useWindow from '~/composables/useWindow/useWindow';
import Organization from '~/components/StructuredData/Organization.vue';
import { useGtm } from '~/composables';

export default defineComponent({
  name: 'HomePage',
  components: {
    CategorySlider,
    FeaturedCategories,
    HeroSlider,
    ProductSlider,
    Organization,
    OffersBanner,
    NewProducts,
  },
  setup() {
    const { addTags } = useCache();
    const { pageGtmPush } = useGtm();
    const { isDesktop, isTablet, isMobile } = useWindow();
    const featuredProductsQuerySettings = {
      pageSize: 10,
      currentPage: 1,
      sort: {
        position: 'ASC',
      },
      filter: {
        category_uid: {
          eq: 'grn:product-list:product-list::6531016dc5fdf86b8d562953',
        },
      },
      customQuery: {
        products: 'simplifiedProductsListCustom',
      },
    };
    const featuredProductsSliderSettings = {
      type: 'slider',
      perView: 4,
      peek: 0,
      gap: 50,
      autoplay: 5000,
      breakpoints: {
        449: {
          perView: 1,
          gap: 45,
        },
        767: {
          perView: 1,
          gap: 45,
          focusAt: 'center',
        },
        920: {
          perView: 2,
          peek: 0,
          gap: 25,
        },
        1023: {
          perView: 3,
          peek: 0,
          gap: 25,
        },
        1100: {
          perView: 3,
          gap: 25,
        },
        1199: {
          perView: 3,
          gap: 25,
        },
        1439: {
          perView: 3,
          gap: 25,
        },
        1919: {
          perView: 4,
          gap: 25,
        },
      },
    };

    const metaTitle = computed(
      () =>
        'BestDigit.it: TV, telefonia, elettrodomestici, audio e informatica | Best Digit'
    );
    const metaDescription = computed(
      () =>
        'BestDigit.it: TV, telefonia, elettrodomestici, audio e informatica | Best Digit'
    );

    useFetch(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'homepage' }]);
      pageGtmPush('Home');
    });

    return {
      metaTitle,
      metaDescription,
      featuredProductsQuerySettings,
      featuredProductsSliderSettings,
      isDesktop,
      isTablet,
      isMobile,
    };
  },
  head() {
    return {
      title: this?.metaTitle,
      meta: [
        { hid: 'robots', name: 'robots', content: 'index, follow' },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this?.metaDescription,
        },
        {
          hid: 'description',
          name: 'description',
          content: this?.metaDescription,
        },
        { hid: 'og:title', property: 'og:title', content: this?.metaTitle },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.article-meta h4 a {
  color: #111111;
  font-weight: 600;
  font-size: var(--font-size--xs);
}
.article-meta {
  margin-top: 10px;
}
.article-item__meta-item:not(:last-child)::after {
  display: inline-block;
  content: '';
  width: 5px;
  height: 5px;
  margin: -1px 10px 0 10px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.4);
  vertical-align: middle;
}
#home {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: none;
  .seo-title {
    display: none;
  }
}
.hero-section {
  //margin: var(--spacer-xl) auto var(--spacer-lg);
  margin: 0 auto var(--spacer-lg);
  ::v-deep .sf-link:hover {
    color: var(--c-white);
  }
  @include for-desktop {
    margin: 222px auto var(--spacer-2xl);
  }
}
.banner-grid {
  --banner-container-width: 50%;
  margin: var(--spacer-xl) 0;
  ::v-deep .sf-link:hover {
    color: var(--c-white);
  }
  @include for-desktop {
    margin: var(--spacer-2xl) 0;
    ::v-deep .sf-link {
      --button-width: auto;
    }
  }
}
.banner {
  &__tshirt {
    background-position: left;
  }
  &-central {
    @include for-desktop {
      --banner-container-flex: 0 0 70%;
    }
  }
}
.similar-products {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--spacer-2xs);
  --heading-padding: 0;
  border-bottom: 1px var(--c-light) solid;
  @include for-desktop {
    border-bottom: 0;
    justify-content: center;
    padding-bottom: 0;
  }
}
.call-to-action {
  background-position: right;
  margin: var(--spacer-xs) 0;
  @include for-desktop {
    margin: var(--spacer-xl) 0 var(--spacer-2xl) 0;
  }
}
.products {
  margin-top: var(--spacer-base);
}
.carousel {
  margin: 0 calc(-1 * var(--spacer-sm)) 0 0;
  @include for-desktop {
    margin: 0;
  }
  &__item {
    margin: 1.375rem 0 var(--spacer-xl) 0;
    @include for-desktop {
      margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
    }
    &__product {
      --product-card-add-button-transform: translate3d(0, 30%, 0);
    }
  }
}
</style>

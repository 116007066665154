<template>
  <div class="hero-slider-banner">
    <HeroSliderBannerSplitColors
      class="hero-slider-banner-heading"
      black-part="best"
      red-part="digit"
      :uppercase="true"
      :split-words="false"
    />
    <HeroSliderBannerSplitColors
      class="hero-slider-banner-title"
      :black-part="textData.title"
      :split-words="true"
    />
    <CustomButton
      icon="arrow_right"
      icon-color="white"
      :link="textData.link"
      :rotate-arrow="true"
      :target="textData.target"
      class="hero-slider-banner-button"
    >
      {{ $t(textData.cta) }}
    </CustomButton>
  </div>
</template>

<script>
import { computed, defineComponent, watch } from '@nuxtjs/composition-api';
import CustomButton from '~/components/CustomButton.vue';
import HeroSliderBannerSplitColors from '~/components/HeroSlider/HeroSliderBannerSplitColors.vue';

export default defineComponent({
  name: 'HeroSliderBanner',
  components: { CustomButton, HeroSliderBannerSplitColors },
  props: {
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '/it',
    },
    cta: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const textData = computed(() => {
      return {
        title: props.title,
        link: props.link,
        target: props.link.includes('https://') ? '_blank' : '_self',
        cta: props.cta,
      };
    });
    return { textData };
  },
});
</script>

<style lang="scss" scoped>
.hero-slider-banner {
  background-color: var(--c-white);
  box-shadow: 0 10px 35px var(--c-transparent-white);
  border-radius: 15px;
  grid-column: 7 / 34;
  height: 50%;
  align-self: center;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  .hero-slider-banner-button {
    position: absolute;
    bottom: -29px;
    margin: 0 auto;
    max-width: 65%;
    @media (min-width: $desktop-min) {
      align-self: flex-start;
      margin-left: 6%;
    }
  }
  .hero-slider-banner-heading {
    font-size: var(--font-size--xs);
    letter-spacing: 4px;
    line-height: var(--font-size--2xl);
    margin: 1em 0 0;
  }
  .hero-slider-banner-title {
    font-size: var(--font-size--m);
    font-weight: var(--font-weight-semibold);
    letter-spacing: -1.8px;
    line-height: 1.2;
    padding: 0.3em 1.5em 0.3em 1em;
  }
}
@media (max-width: $tablet-max) {
  .hero-slider-banner {
    grid-row: 50 / 90;
    grid-column: 1 / 2;
    width: 90vw;
    margin: 1.25rem auto 3rem;
    padding: 0;
    align-items: center;
    .hero-slider-banner-heading {
      font-size: var(--font-size--5xs);
      letter-spacing: 2.2px;
      line-height: var(--font-size--sm);
      margin: 0;
      padding: 0 2em;
      align-self: flex-start;
    }
    .hero-slider-banner-title {
      font-size: var(--font-size--s);
      font-weight: var(--font-weight-semibold);
      letter-spacing: -1.2px;
      line-height: var(--font-size--m);
      padding: 0.3em 0.5em 1em;
    }
    .custom-button {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      font-size: var(--font-size--5xs);
      white-space: wrap;
      text-align: center;
    }
  }
}
@media (min-width: $mobile-max) and (max-width: $mobile-max) {
  .hero-slider-banner {
    height: 60%;
    grid-row: 65 / 95;
  }
}
@media (max-width: 1440px) {
  .hero-slider-banner {
    .custom-button {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      font-size: var(--font-size--5xs);
      white-space: wrap;
      text-align: center;
    }
  }
}
@media (max-width: 1920px) {
  .hero-slider-banner {
    height: fit-content;
    min-width: 40%;
  }
}
</style>

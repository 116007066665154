<template>
  <div class="offers-banner-image">
    <div class="only-desktop" :class="[position]">
      <span class="image-wrapper-content">
        <nuxt-img
          :src="imageDesktop"
          :alt="alt"
          loading="lazy"
          width="100"
          height="100"
          placeholder="/assets/product_placeholder.svg"
          class="image-wrapper-logo"
        />
      </span>
      <div class="content" :class="[titleSize]">
        <span class="content-logo">
          <nuxt-img
            :src="imageLogo"
            :alt="alt"
            width="-1"
            height="-1"
            placeholder="/assets/product_placeholder.svg"
            class="content-logo-image"
          />
        </span>
        <div class="title">
          <div class="white" v-if="title.white">
            {{ $t(title.white) }}
          </div>
          <div class="black" v-if="title.black">
            {{ $t(title.black) }}
          </div>
          <div class="red" v-if="title.red">
            {{ $t(title.red) }}
          </div>
        </div>
        <CustomButton
          icon="arrow_right"
          icon-color="white"
          icon-position="right"
          :link="link"
          :rotate-arrow="true"
        >
          <span>{{ $t(`${this.$props.buttonLabel}`) }}</span>
        </CustomButton>
      </div>
    </div>
    <div class="only-mobile">
      <nuxt-link :to="link">
        <span class="content-logo">
          <nuxt-img
            :src="imageMobile"
            :alt="alt"
            loading="lazy"
            width="100"
            height="100"
            placeholder="/assets/product_placeholder.svg"
            class="content-logo-image"
          />
        </span>
        <span class="sf-input mobile-cta">{{
          $t(`${this.$props.buttonLabel}`)
        }}</span>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import { addBasePath } from '~/helpers/addBasePath';
import CustomButton from '~/components/CustomButton.vue';

export default {
  name: 'OffersBannerImage',
  components: {
    CustomButton,
  },
  props: {
    imageDesktop: {
      type: String,
      required: true,
      default: '',
    },
    imageMobile: {
      type: String,
      required: true,
      default: '',
    },
    imageLogo: {
      type: String,
      required: true,
      default: '',
    },
    alt: {
      type: String,
      required: true,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    title: {
      type: Object,
      default: () => {},
    },
    titleSize: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      addBasePath,
    };
  },
};
</script>

<style lang="scss">
.offers-banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  .only-desktop {
    display: block;
    align-items: center;
    justify-content: left;
    &.center {
      justify-content: center;
      .content {
        margin-left: 0.5em;
      }
    }

    width: 100%;
    height: 100%;
    object-fit: cover;

    .image-wrapper-content {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;

      .image-wrapper-logo {
        width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
        position: static;
        border-radius: 10px;
      }

      .sf-image--placeholder {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .content {
      font-size: var(--font-size--m);
      font-weight: var(--font-weight-semibold);
      //position: absolute;
      margin-left: 1.5em;
      max-width: 9em;
      &.large {
        font-size: var(--font-size--ml);
      }
      &.x-large {
        font-size: var(--font-size--l);
      }
      &.xx-large {
        font-size: var(--font-size--2xl);
      }
      &:not(.large, .x-large, .xx-large) {
        margin-left: 1.5em;
        .title {
          padding-left: 0.5em;
          padding-right: 0.5em;
        }
      }

      .content-logo {
        object-fit: none;
        .content-logo-image {
          width: auto;
          object-fit: none;
        }
        .sf-image--placeholder {
          width: 30%;
          height: auto;
          object-fit: contain;
        }
      }

      .title {
        .black,
        .red,
        .white {
          line-height: 1.2em;
        }
        .red {
          color: var(--c-primary);
        }
        .white {
          color: var(--c-white);
        }
      }
    }

    .custom-button {
      display: flex;
      max-width: max-content;
      font-size: var(--font-size--3xs);
      // set smaller font size for screen with max with 1440px
      @media (max-width: 1440px) {
        font-size: var(--font-size--4xs);
      }
    }

    .sf-icon-path {
      max-height: 1.1rem;
      max-width: 1.1rem;
    }
  }
  .only-mobile {
    display: none;
    a {
      display: flex;
      flex-direction: column;
      .mobile-cta {
        background-color: var(--c-primary);
        color: var(--c-white);
        width: 100%;
        height: auto;
        min-height: initial;
        font-size: var(--font-size--4xs);
        font-weight: var(--font-weight-semibold);
        text-transform: none;
        // make border radius only on bottom
        border-radius: 0 0 5px 5px;
      }
    }
  }
}

//desktop
@media (min-width: $desktop-xl-min) and (max-width: $desktop-xxl-min - 1) {
  .offers-banner-image {
    .only-desktop {
      .content {
        font-size: var(--font-size--s);

        &.large {
          font-size: var(--font-size--sm);
        }

        &.x-large {
          font-size: var(--font-size--m);
        }

        &.xx-large {
          font-size: var(--font-size--ml);
        }
        &:not(.large, .x-large, .xx-large) {
          margin-left: 0.5em;
        }
      }
    }
  }
}

//desktop
@media (min-width: $desktop-l-min) and (max-width: $desktop-xl-min - 1) {
  .offers-banner-image {
    .only-desktop {
      .content {
        font-size: var(--font-size--xs);

        &.large {
          font-size: var(--font-size--s);
        }

        &.x-large {
          font-size: var(--font-size--sm);
        }

        &.xx-large {
          font-size: var(--font-size--m);
        }
        &:not(.large, .x-large, .xx-large) {
          margin-left: 0.5em;
        }
      }
    }
  }
}

//small desktop
@media (min-width: $desktop-min) and (max-width: $desktop-l-min - 1) {
  .offers-banner-image {
    .only-desktop {
      .content {
        font-size: var(--font-size--3xs);

        &.large {
          font-size: var(--font-size--xs);
        }

        &.x-large {
          font-size: var(--font-size--s);
        }

        &.xx-large {
          font-size: var(--font-size--sm);
        }
      }

      .custom-button {
        font-size: var(--font-size--base);
        padding: 10px 20px;
      }

      .sf-icon-path {
        max-height: 0.9rem;
        max-width: 0.9rem;
      }
    }
  }
}

//mobile + tablet
@media (max-width: $tablet-max + 1) {
  .offers-banner-image {
    .only-desktop {
      display: none;
    }

    .only-mobile {
      display: block;

      .content-logo {
        width: 100%;
        height: 100%;
        object-fit: cover;

        .content-logo-image {
          width: 100%;
          height: auto;
        }

        .sf-image--placeholder {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
